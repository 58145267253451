import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { useTheme } from '@mui/material/styles';
import { axisClasses } from '@mui/x-charts';

export default function LineChartComponent(props) {
    const { dataset, xKey, yKey } = props;
    const theme = useTheme();
    return (
        <LineChart
            dataset={dataset}
            margin={{
                top: 16,
                right: 20,
                left: 70,
                bottom: 30,
            }}
            xAxis={[
                {
                    dataKey: xKey,
                    scaleType: 'point',
                    valueFormatter: (value) => value.substring(0, 3),
                    tickNumber: 5,
                    tickLabelStyle: theme.typography.body2,
                },
            ]}
            yAxis={[
                {
                    labelStyle: {
                        ...(theme.typography.body1),
                        fill: theme.palette.text.primary,
                    },
                    tickLabelStyle: theme.typography.body2,
                    valueFormatter: (value) => `${value / 1000000} mio`,
                    max: 50000000,
                    tickNumber: 4,
                },
            ]}
            series={[
                {
                    dataKey: yKey
                },
            ]}
            sx={{
                [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
                [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
                [`& .${axisClasses.left} .${axisClasses.label}`]: {
                    transform: 'translateX(-25px)',
                },
            }}
            // width={600}
            height={300}
        />
    );
}