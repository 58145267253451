import api from "./api"

export const createOutcomeTransaction = async (data) => {
  return await api.post('/outcome-transaction/create-outcome-transaction', data, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getAllOutcomeTransaction = async (page, pageSize) => {
  return await api.get(`/outcome-transaction/get-all-outcome-transaction?page=${page}&pageSize=${pageSize}`, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getDescriptionOutcomeTransaction = async (id) => {
  return await api.get(`/outcome-transaction/get-description/${id}`, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const updateDescriptionOutcomeTransaction = async (id, data) => {
  return await api.put(`/outcome-transaction/update-description/${id}`, data, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const deleteOutcomeTransaction = async (id) => {
  return await api.delete(`/outcome-transaction/delete-outcome-transaction/${id}`, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}

export const getTotalOutcomeTransactionPerMonthInYear = async (year) => {
  return await api.get(`/outcome-transaction/get-total-outcome?year=${year}`, {
    headers: {
      Authorization: localStorage.getItem("token")
    }
  });
}