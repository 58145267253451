import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { Modal, Typography } from 'antd';
import { primaryColor, whiteColor } from '../../Utils/Constants';
import { FormattedMessage } from 'react-intl';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';

const ModalDescription = (props) => {
    const { modalOpen, setModalOpen, description, setDescription, handleUpdateDescription } = props;
    return (
        <Modal
            // title="Vertically centered modal dialog"
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={800}
            footer={[
                <Button style={{ backgroundColor: whiteColor, color: primaryColor }} variant="contained" key="back" onClick={() => setModalOpen(false)}>
                    Kembali
                </Button>,
                <Button style={{ backgroundColor: primaryColor, color: whiteColor, marginLeft: '20px' }} variant="contained" key="submit" type="primary" onClick={handleUpdateDescription}>
                    <FormattedMessage id="submit" />
                </Button>
            ]}
        >
            <Typography variant='h4' style={{ color: primaryColor }}>
                Deskripsi
            </Typography>
            <div style={{ paddingTop: "30px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <TextArea onChange={(e) => setDescription(e.target.value)} value={description} rows={3} />
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default ModalDescription;