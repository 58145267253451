import React, { useEffect, useState } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import { DatePicker, Form, Input, Select, Skeleton } from 'antd';
import { Button, Container, Grid, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { MONTHS, primaryColor, redColor, secondaryColor, whiteColor } from '../../Utils/Constants';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import TextArea from 'antd/es/input/TextArea';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment';
import { createOutcomeTransaction, deleteOutcomeTransaction, getAllOutcomeTransaction, getDescriptionOutcomeTransaction, getTotalOutcomeTransactionPerMonthInYear, updateDescriptionOutcomeTransaction } from '../../Components/api/outcomeTransaction';
import { getListVehicle } from '../../Components/api/vehicle';
import ModalDescription from '../../Components/Modal/ModalDescription';
import LineChartComponent from '../../Components/Chart/LineChart/LineChart';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const OutcomeTransaction = () => {
  const [form] = Form.useForm();
  const [transaction, setTransaction] = useState([]);
  const [listVehicle, setListVehicle] = useState([]);
  const [totalOutcome, setTotalOutcome] = useState([]);
  const [showOutcome, setShowOutcome] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10;

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    getTotalOutcomeTransactionPerMonthInYear(moment().format('YYYY'))
      .then((res) => {
        const defaultData = [{
          month: '-',
          totalIncome: 0
        }];
        const defaultData2 = [{
          month: '',
          totalIncome: 0
        }];
        const sortOutcome = res.data.data.sort((a, b) => {
          return MONTHS.indexOf(a.month) - MONTHS.indexOf(b.month);
        });
        const data = [...defaultData, ...sortOutcome, ...defaultData2];
        setTotalOutcome(data);
      })
  }, [])

  useEffect(() => {
    getListVehicle()
      .then((res) => {
        setListVehicle(res.data.data);
      })
  }, [])

  useEffect(() => {
    setLoading(true);
    getAllOutcomeTransaction(page, PAGE_SIZE)
      .then((res) => {
        setTransaction(res.data.data.data);
        setTotalPage(Math.ceil(res.data.data.totalData / PAGE_SIZE));
        setLoading(false);
      })
  }, [page])

  const handleDetail = (id) => {
    MySwal.showLoading()
    getDescriptionOutcomeTransaction(id)
      .then((res) => {
        setDescription(res.data.data.description);
        setId(id);
      })
      .then(() => {
        setModalOpen(true);
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Get Description</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
      .finally(() => {
        MySwal.close();
      })
  }

  const handleUpdateDescription = () => {
    MySwal.showLoading()
    updateDescriptionOutcomeTransaction(id, { description: description })
      .then(() => {
        MySwal.fire({
          title: <strong>Update Description Success</strong>,
          timer: 1500,
          showConfirmButton: false,
          icon: 'success'
        })
      })
      .then(() => {
        setModalOpen(false);
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Update Description</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
      .finally(() => {
        MySwal.close();
      })
  }

  const handleSubmit = (values) => {
    MySwal.showLoading()
    let notvalid = false;
    if (values.vehicleName === '' || values.eventDate === ''
      || values.vehicleName === undefined || values.eventDate === undefined) {
      notvalid = true;
    }
    if (notvalid) {
      MySwal.fire({
        title: <strong>Failed Create Transaction</strong>,
        html: <i>Vehicle Name and Police Number cannot be empty</i>,
        showConfirmButton: false,
        timer: 1500,
        icon: 'error'
      })
    } else {
      const splitVehicle = values.vehicleName.split('-');
      values.vehicleName = splitVehicle[0];
      values.policeNumber = splitVehicle[1];
      createOutcomeTransaction(values)
        .then((res) => {
          MySwal.fire({
            title: <strong>Create Outcome Transaction Success</strong>,
            timer: 1500,
            showConfirmButton: false,
            icon: 'success'
          })
        })
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          MySwal.fire({
            title: <strong>Failed Create Outcome Transaction</strong>,
            html: <i>{err.response.data.message}</i>,
            showConfirmButton: false,
            timer: 1500,
            icon: 'error'
          })
        })
    }
  }

  const handleDelete = (id) => {
    MySwal.fire({
      title: <strong>Are you sure?</strong>,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: redColor,
      cancelButtonColor: primaryColor,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.showLoading()
        deleteOutcomeTransaction(id)
          .then(() => {
            MySwal.fire({
              title: <strong>Delete Outcome Transaction Success</strong>,
              timer: 1500,
              showConfirmButton: false,
              icon: 'success'
            })
            window.location.reload();
          })
      }
    })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Delete Outcome Transaction</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
  }


  const generateEachSkeleton = (id) => {
    return (
      <StyledTableRow
        key={id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StyledTableCell component="th" scope="row">
          <Skeleton loading={transaction.length === 0} active paragraph={0} />
        </StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
      </StyledTableRow>
    )
  }

  const generateSkeleton = () => {
    return (
      <>
        {generateEachSkeleton(1)}
        {generateEachSkeleton(2)}
        {generateEachSkeleton(3)}
        {generateEachSkeleton(4)}
        {generateEachSkeleton(5)}
      </>
    );
  }

  const handlePagination = (event, value) => {
    setPage(value);
  }

  return (
    <div>
      <NavBar isPageAdmin={true} />
      {
        showOutcome && <LineChartComponent dataset={totalOutcome} xKey={"month"} yKey={"total_outcome"} />
      }
      <Container style={{ textAlign: 'center', width: "80%", marginTop: "20px" }}>
        <Button onClick={() => setShowOutcome(!showOutcome)} style={{ backgroundColor: secondaryColor, color: whiteColor, width: "100%" }}>
          {showOutcome ? 'Tutup Grafik' : 'Lihat Grafik Pengeluaran'}
        </Button>
        <br />
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Form.Item name={'vehicleName'} label={'Vehicle'}>
                <Select
                  placeholder={'Select Vehicle'}
                  style={{ textAlign: "left" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={listVehicle}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'vehicleType'} label={'Type'}>
                <Select
                  style={{ textAlign: "left" }}
                  showSearch
                  optionFilterProp="children"
                  // onSearch={onSearch}
                  options={
                    [
                      { label: 'CAR', value: 'CAR' },
                      { label: 'MOTORCYCLE', value: 'MOTORCYCLE' }
                    ]
                  }
                  defaultValue={'MOTORCYCLE'}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name={'outcomeType'} label={'Type'}>
                <Select
                  style={{ textAlign: "left" }}
                  showSearch
                  optionFilterProp="children"
                  // onSearch={onSearch}
                  options={
                    [
                      { label: 'ANNUAL TAX', value: 'ANNUAL_TAX' },
                      { label: 'SERVICE', value: 'SERVICE' }
                    ]
                  }
                  defaultValue={'SERVICE'}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'price'} label={'Price'} >
                <Input placeholder={'1000000'} />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name='eventDate' label={'Date'}>
                <DatePicker
                  placeholder={`${moment().format('YYYY-MM-DD')}`}
                  style={{ width: "100%", marginRight: "3%" }}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Form.Item name={'description'} label={'Notes'} >
            <TextArea rows={3} placeholder={'Notes'} />
          </Form.Item>
          <Button type='submit' style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%" }}>
            <TypographyCustom
              content="headerFormSubmit"
            />
          </Button>
        </Form>
      </Container>

      <Container style={{ marginBlock: "2rem" }}>
        <TableContainer component={Paper}>
          <Table style={{ textAlign: 'center' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Vehicle</StyledTableCell>
                <StyledTableCell>Police Number</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Price</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && generateSkeleton()}
              {
                transaction !== null && !loading && transaction.map((car, index) => {
                  return (
                    <StyledTableRow
                      key={car.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => handleDetail(car.id)}
                    >
                      <StyledTableCell component="th" scope="row">
                        {car.vehicleName}
                      </StyledTableCell>
                      <StyledTableCell>{car.policeNumber}</StyledTableCell>
                      <StyledTableCell>{car.outcomeType}</StyledTableCell>
                      <StyledTableCell>{car.price}</StyledTableCell>
                      <StyledTableCell>{moment(car.eventDate).format('YYYY-MM-DD')}</StyledTableCell>
                      <StyledTableCell>
                        <button
                          style={{ color: whiteColor, border: 'none', cursor: 'pointer', backgroundColor: redColor }}
                          onClick={() => handleDelete(car.id)}>
                          Delete
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ textAlign: 'center', marginTop: "1rem", display: 'flex' }}>
          <Pagination count={totalPage} page={page} onChange={handlePagination} showFirstButton showLastButton />
        </div>
      </Container>
      <ModalDescription
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        description={description}
        setDescription={setDescription}
        handleUpdateDescription={handleUpdateDescription}
      />
    </div>
  );
};

export default OutcomeTransaction;