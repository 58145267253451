export const base_url_images = '/images/';
export const primaryColor = '#254790';
export const primaryShadedColor = 'rgba(35,73,146, 0.4)';
export const secondaryColor = '#3d4569';
export const whiteColor = '#FFFFFF';
export const blackColor = '#000000';
export const greenColor = '#44bb70';
export const redColor = '#ff0000';
export const fontFamily = 'Montserrat, sans-serif';
export const LINK_NUMBER_WA = 'https://wa.me/6285176750446';
export const MONTHS = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];