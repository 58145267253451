import React, { useEffect, useState } from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import { DatePicker, Form, Input, Select, Skeleton } from 'antd';
import { Button, Container, Grid, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { greenColor, MONTHS, primaryColor, redColor, secondaryColor, whiteColor } from '../../Utils/Constants';
import TypographyCustom from '../../Components/Typography/TypographyCustom';
import TextArea from 'antd/es/input/TextArea';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { createTransactionAdmin, deleteTransaction, getAllTransactionV2, getDescriptionTransaction, getTotalIncomeTransactionPerMonthInYear, getTransactionActive, updateDescriptionTransaction } from '../../Components/api/transaction';
import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment';
import { loginKtp, validateKtp } from '../../Components/api/ktp';
import { getListVehicle } from '../../Components/api/vehicle';
import LineChartComponent from '../../Components/Chart/LineChart/LineChart';
import ModalDescription from '../../Components/Modal/ModalDescription';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: primaryColor,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transaction = () => {
  const [form] = Form.useForm();
  const [transaction, setTransaction] = useState([]);
  const [listVehicle, setListVehicle] = useState([]);
  const [totalIncome, setTotalIncome] = useState([]);
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [showIncome, setShowIncome] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAllTransaction, setIsAllTransaction] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10;

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    getTotalIncomeTransactionPerMonthInYear(moment().format('YYYY'))
      .then((res) => {
        const defaultData = [{
          month: '-',
          totalIncome: 0
        }];
        const defaultData2 = [{
          month: '',
          totalIncome: 0
        }];
        const sortIncome = res.data.data.sort((a, b) => {
          return MONTHS.indexOf(a.month) - MONTHS.indexOf(b.month);
        });
        const data = [...defaultData, ...sortIncome, ...defaultData2];
        setTotalIncome(data);
      })
  }, [])

  useEffect(() => {
    getListVehicle()
      .then((res) => {
        setListVehicle(res.data.data);
      })
  }, [])

  useEffect(() => {
    setLoading(true);
    if (isAllTransaction) {
      getAllTransactionV2(page, PAGE_SIZE)
        .then((res) => {
          setTransaction(res.data.data.data);
          setTotalPage(Math.ceil(res.data.data.totalData / PAGE_SIZE));
          setLoading(false);
        })
    } else {
      getTransactionActive()
        .then((res) => {
          setTransaction(res.data.data);
          setLoading(false);
        })
    }
  }, [isAllTransaction, page])

  const handleSubmit = (values) => {
    let notvalid = false;
    if (values.carName === '' || values.pickUpDate === '' || values.returnDate === '' || values.price === ''
      || values.carName === undefined || values.pickUpDate === undefined || values.returnDate === undefined || values.price === undefined) {
      notvalid = true;
    }
    if (notvalid) {
      MySwal.fire({
        title: <strong>Failed Create Transaction</strong>,
        html: <i>Car Name, Police Number, Pick Up Date, Return Date cannot be empty</i>,
        showConfirmButton: false,
        timer: 1500,
        icon: 'error'
      })
    } else {
      // set modal are you sure
      const splitVehicle = values.carName.split('-');
      values.carName = splitVehicle[0];
      values.policeNumber = splitVehicle[1];
      MySwal.fire({
        title: <strong>Are you sure?</strong>,
        html: <div>
          <i>You want to create this transaction?</i><br />
          <b>Car Name:</b> {values.carName}<br />
          <b>Price:</b> {values.price}<br />
          <b>Name:</b> {values.name}<br />
          <b>Phone Number:</b> {values.phoneNumber}<br />
          <b>Email:</b> {values.email || '-'}<br />
          <b>Location:</b> {values.location || '-'}<br />
          <b>Description:</b> {values.description || '-'}
        </div>,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: primaryColor,
        cancelButtonColor: redColor,
        confirmButtonText: 'Yes, create it!'
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.showLoading()
          createTransactionAdmin(values)
            .then((res) => {
              MySwal.fire({
                title: <strong>Create Transaction Success</strong>,
                timer: 1500,
                showConfirmButton: false,
                icon: 'success'
              })
            })
            .catch((err) => {
              MySwal.fire({
                title: <strong>Failed Create Transaction</strong>,
                html: <i>{err.response.data.message}</i>,
                showConfirmButton: false,
                timer: 1500,
                icon: 'error'
              })
            })
            .finally(() => {
              window.location.reload();
            })
        }
      }).catch((err) => {
        MySwal.fire({
          title: <strong>Failed Create Transaction</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      });

    }
  }

  const handleDetail = (id) => {
    MySwal.showLoading()
    getDescriptionTransaction(id)
      .then((res) => {
        setDescription(res.data.data.description);
        setId(id);
      })
      .then(() => {
        setModalOpen(true);
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Get Description</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
      .finally(() => {
        MySwal.close();
      })
  }

  const handleUpdateDescription = () => {
    MySwal.showLoading()
    updateDescriptionTransaction(id, { description: description })
      .then(() => {
        MySwal.fire({
          title: <strong>Update Description Success</strong>,
          timer: 1500,
          showConfirmButton: false,
          icon: 'success'
        })
      })
      .then(() => {
        setModalOpen(false);
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Update Description</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
      .finally(() => {
        MySwal.close();
      })
  }

  const handleDelete = (event, id) => {
    event.stopPropagation();
    MySwal.fire({
      title: <strong>Are you sure?</strong>,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: redColor,
      cancelButtonColor: primaryColor,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.showLoading()
        deleteTransaction(id)
          .then(() => {
            MySwal.fire({
              title: <strong>Delete Transaction Success</strong>,
              timer: 1500,
              showConfirmButton: false,
              icon: 'success'
            })
          })
          .finally(() => {
            window.location.reload();
          });
      }
    })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Delete Transaction</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
  }


  const generateEachSkeleton = (id) => {
    return (
      <StyledTableRow
        key={id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StyledTableCell component="th" scope="row">
          <Skeleton loading={transaction.length === 0} active paragraph={0} />
        </StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
        <StyledTableCell><Skeleton loading={transaction.length === 0} active paragraph={0} /></StyledTableCell>
      </StyledTableRow>
    )
  }

  const generateSkeleton = () => {
    return (
      <>
        {generateEachSkeleton(1)}
        {generateEachSkeleton(2)}
        {generateEachSkeleton(3)}
        {generateEachSkeleton(4)}
        {generateEachSkeleton(5)}
      </>
    );
  }

  const handleExtend = (event, car) => {
    event.stopPropagation();
    form.setFieldsValue({
      carName: `${car.carName}-${car.policeNumber}`,
      name: car.name,
      phoneNumber: car.phoneNumber,
      email: car.email,
      location: car.location,
      typeVehicle: car.typeVehicle,
      price: car.price,
      description: car.description
    })
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handlePagination = (event, value) => {
    setPage(value);
  }

  const validateNik = () => {
    MySwal.showLoading()
    // const tokenClient = getClientToken();
    const payloadData = {
      nik: form.getFieldValue('nik'),
      name: form.getFieldValue('name'),
      // tokenClient
    }
    validateKtp(payloadData)
      .then((res) => {
        const isValid = res.data.data.content[0].NAMA_LGKP
          && res.data.data.content[0].NAMA_LGKP === "Sesuai (100)"
        if (isValid) {
          MySwal.fire({
            title: <strong>Validate Success</strong>,
            html: <i>NIK {form.getFieldValue('nik')} is valid</i>,
            timer: 1500,
            showConfirmButton: false,
            icon: 'success'
          })
        } else {
          MySwal.fire({
            title: <strong>Validate Failed</strong>,
            html: <i>NIK {form.getFieldValue('nik')} is not valid</i>,
            showConfirmButton: false,
            timer: 1500,
            icon: 'error'
          })
        }
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Error Validation</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      })
  }

  const getClientToken = async () => {
    if (localStorage.getItem('token_client')) {
      return localStorage.getItem('token_client');
    }
    const token = await loginKtp()
      .then((res) => {
        const token = res.data.data.token;
        localStorage.setItem('token_client', res.data.data.token);
        return token;
      })
      .catch((err) => {
        MySwal.fire({
          title: <strong>Failed Get Token</strong>,
          html: <i>{err.response.data.message}</i>,
          showConfirmButton: false,
          timer: 1500,
          icon: 'error'
        })
      });
    return token;
  }

  return (
    <div>
      <NavBar isPageAdmin={true} />
      {
        showIncome && <LineChartComponent dataset={totalIncome} xKey={"month"} yKey={"totalIncome"} />
      }
      <Container style={{ textAlign: 'center', width: "80%", marginTop: "20px" }}>
        <Button onClick={() => setShowIncome(!showIncome)} style={{ backgroundColor: secondaryColor, color: whiteColor, width: "100%" }}>
          {showIncome ? 'Tutup Grafik' : 'Lihat Grafik Pemasukan'}
        </Button>
        <br />
        <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Form.Item name={'carName'} label={'Vehicle'}>
                <Select
                  placeholder={'Select Vehicle'}
                  style={{ textAlign: "left" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={listVehicle}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'typeVehicle'} label={'Type'}>
                <Select
                  style={{ textAlign: "left" }}
                  showSearch
                  optionFilterProp="children"
                  // onSearch={onSearch}
                  options={
                    [
                      { label: 'CAR', value: 'CAR' },
                      { label: 'MOTORCYCLE', value: 'MOTORCYCLE' }
                    ]
                  }
                  defaultValue={'MOTORCYCLE'}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name={'price'} label={'Price'} >
                <Input placeholder={'1000000'} />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'name'} label={'Customer Name'} >
                <Input placeholder={'Customer Name'} />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name={'phoneNumber'} label={'Phone Number'} >
                <Input placeholder={'Phone Number'} />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'email'} label={'Email'} >
                <Input placeholder={'Email'} />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name={'location'} label={'Address'} >
                <Input placeholder={'Address'} />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name='pickUpDate' label={'Pick Up Date'}>
                <DatePicker
                  placeholder={`${moment().format('YYYY-MM-DD')}`}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item name='returnDate' label={'Return Date'}>
                <DatePicker
                  placeholder={`${moment().format('YYYY-MM-DD')}`}
                  style={{ width: "100%", marginRight: "3%" }}
                />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Form.Item name={'nik'} label={'NIK'} >
                <Input placeholder={'NIK'} />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label={'  '} >
                <Button onClick={validateNik} style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%" }}>
                  <TypographyCustom
                    content="validateNik"
                  />
                </Button>
              </Form.Item>
            </Grid>
          </Grid>
          <Form.Item name={'description'} label={'Notes'} >
            <TextArea rows={3} placeholder={'Notes'} />
          </Form.Item>
          <Button type='submit' style={{ backgroundColor: primaryColor, color: whiteColor, width: "100%" }}>
            <TypographyCustom
              content="submit"
            />
          </Button>
        </Form>
      </Container>

      <Container style={{ marginBlock: "2rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button onClick={() => setIsAllTransaction(!isAllTransaction)} style={{ backgroundColor: greenColor, color: whiteColor, width: "100%", marginBottom: "1rem" }}>
              {isAllTransaction ? 'Lihat Transaksi Berjalan' : 'Lihat Semua Transaksi'}
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table style={{ textAlign: 'center' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Vehicle</StyledTableCell>
                <StyledTableCell>Police Number</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Phone Number</StyledTableCell>
                <StyledTableCell>Pick Up Date</StyledTableCell>
                <StyledTableCell>Return Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && generateSkeleton()}
              {
                transaction !== null && !loading && transaction.map((car, index) => {
                  return (
                    <StyledTableRow
                      key={car.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => handleDetail(car.id)}
                    >
                      <StyledTableCell component="th" scope="row">
                        {car.carName}
                      </StyledTableCell>
                      <StyledTableCell>{car.policeNumber}</StyledTableCell>
                      <StyledTableCell>{car.name}</StyledTableCell>
                      <StyledTableCell>{car.phoneNumber}</StyledTableCell>
                      <StyledTableCell>{car.pickUpDate}</StyledTableCell>
                      <StyledTableCell>{car.returnDate}</StyledTableCell>
                      <StyledTableCell>
                        <button
                          style={{ color: whiteColor, border: 'none', cursor: 'pointer', backgroundColor: primaryColor }}
                          onClick={(e) => handleExtend(e, car)}>
                          Extend
                        </button>
                        {' '}
                        <button
                          style={{ color: whiteColor, border: 'none', cursor: 'pointer', backgroundColor: redColor }}
                          onClick={(e) => handleDelete(e, car.id)}>
                          Delete
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          isAllTransaction &&
          <div style={{ textAlign: 'center', marginTop: "1rem", display: 'flex' }}>
            <Pagination count={totalPage} page={page} onChange={handlePagination} showFirstButton showLastButton />
          </div>
        }
        <ModalDescription
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          description={description}
          setDescription={setDescription}
          handleUpdateDescription={handleUpdateDescription}
        />
      </Container>
    </div>
  );
};

export default Transaction;